import { PayloadAction, createAsyncThunk, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { extendedBillingSlice } from '../setting/billingSlice';
import AuthServices, { extendedAuthApiSlice } from './authService';

// type Profile = {
//   user_identification: string;
//   user_user_name: string | null;
//   user_last_name: string | null;
//   user_first_name: string | null;
//   user_birth_date: string | null;
//   user_avatar: string | null;
//   user_creation_date_time: string;
//   user_last_connection: string;
//   user_phone: string | null;
//   user_email: string;
//   user_country_code: string | null;
//   user_is_active: boolean;
//   user_role: string;
//   user_type: string;
//   user_groups: string;
//   user_permissions: string[] | []
// }

export type Auth = {
  is_rememberMe: number | null;
  is_authenticated: boolean;
  is_requiredPasswordUpdate: boolean;
  is_renew_payment_required: boolean;
  is_payment_verified: boolean;
  is_new_user: boolean;
  is_active: boolean;
  is_trial: boolean;
  trial_left_days: number;
  access_token: string;
  profile?: any;
  billing: {
    plans: {
      [key: string]: {
        status: string;
        description: string;
        cards: any[];
        active_card: string;
        history: [];
      };
    };
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
};

const initialState = {
  is_authenticated: false,
  is_requiredPasswordUpdate: false,
  is_renew_payment_required: false,
  is_payment_verified: false,
  is_new_user: false,
  is_active: false,
  is_trial: false,
  trial_left_days: 0,
  access_token: '',
  profile: {},
  billing: {
    plans: {},
  },
  status: 'idle',
  error: null,
  is_rememberMe: null,
} as Auth;

export const resendEmailVerification = createAsyncThunk(
  'auth/profile',
  async (customer_identification: string, { rejectWithValue }) => {
    try {
      const { data: res } = await AuthServices.resendEmailVerification(customer_identification);
      return res;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogin: (
      state,
      action: PayloadAction<{
        userIdentification: string;
        userEmail: string;
        requiredPasswordUpdate: boolean;
        isNewUser?: boolean;
        userIsActive: boolean;
        token: string;
      }>,
    ) => {
      state.is_authenticated = true;
      state.profile.user_identification = action.payload.userIdentification;
      state.is_requiredPasswordUpdate = action.payload.requiredPasswordUpdate;
      state.access_token = action.payload.token;
      state.is_new_user = action.payload.isNewUser || false;
      state.is_active = action.payload.userIsActive;
      state.profile.user_email = action.payload.userEmail;
    },
    userLogOut: () => initialState,
    setIsNewUser: (state, action) => {
      state.is_new_user = action.payload.isNewUser ? action.payload.isNewUser : false;
    },
    setRememberMe: (state, action) => {
      state.is_rememberMe = action.payload ?? null;
    },
    clearRequiredPasswordUpdate: (state) => {
      state.is_requiredPasswordUpdate = false;
    },
    toggleRenewPaymentRequiredStatus: (state, action) => {
      state.is_renew_payment_required = action.payload;
    },
    togglePaymentVerificationStatus: (state, action) => {
      state.is_payment_verified = action.payload;
    },
    tokenSetter: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(extendedAuthApiSlice.endpoints.verifyPayment.matchFulfilled, (state, { payload }) => {
      state.is_new_user = payload.element.is_new_user;
      state.is_trial = payload.element.is_trial;
      state.trial_left_days = payload.element.trial_left_days;
      state.is_payment_verified = payload.element.active_plans.length > 0 ? true : false;
      state.is_renew_payment_required = payload.element.is_trial && payload.element.trial_left_days <= 0 ? true : false;
    });

    builder.addMatcher(extendedBillingSlice.endpoints.freeSubscription.matchFulfilled, (state, { payload }) => {
      state.is_payment_verified = true;
    });

    builder.addMatcher(extendedAuthApiSlice.endpoints.checkStatus.matchFulfilled, (state, { payload }) => {
      state.is_active = payload.element.user_is_active;
    });

    builder.addMatcher(extendedAuthApiSlice.endpoints.profile.matchFulfilled, (state, { payload }) => {
      state.profile = payload.element;
    });

    builder.addMatcher(extendedAuthApiSlice.endpoints.changeEmail.matchFulfilled, (state, { payload }) => {
      state.profile.user_email = payload.element;
    });

    builder.addMatcher(extendedBillingSlice.endpoints.getPlans.matchFulfilled, (state, { payload }) => {
      let normalized = {};

      if (payload.status) {
        payload.planList.forEach((plan: any) => {
          normalized = {
            ...normalized,
            [plan.plan]: { status: plan.status, description: plan.description, cards: [] },
          };
        });
      }

      state.billing.plans = normalized;
    });

    builder.addMatcher(extendedBillingSlice.endpoints.getPlanCards.matchFulfilled, (state, { payload, meta }) => {
      state.billing.plans[meta.arg.originalArgs.plan].cards = payload;
    });

    builder.addMatcher(
      extendedBillingSlice.endpoints.getPlanDetailedInfo.matchFulfilled,
      (state, { payload, meta }) => {
        state.billing.plans[meta.arg.originalArgs.plan].active_card = payload.default_payment_method;
      },
    );

    builder.addMatcher(extendedBillingSlice.endpoints.putMakeCardAsDefault.matchFulfilled, (state, { meta }) => {
      state.billing.plans[meta.arg.originalArgs.plan].active_card = meta.arg.originalArgs.payment_method_id;
    });

    builder.addMatcher(extendedBillingSlice.endpoints.removeSubscriptionCard.matchFulfilled, (state, { meta }) => {
      state.billing.plans[meta.arg.originalArgs.plan].cards = state.billing.plans[
        meta.arg.originalArgs.plan
      ].cards.filter((item) => item.paymentMethodId !== meta.arg.originalArgs.payment_method_id);
    });

    builder.addMatcher(extendedBillingSlice.endpoints.postCancelSubscription.matchFulfilled, (state, { meta }) => {
      state.billing.plans[meta.arg.originalArgs.plan].cards = [];
      state.billing.plans[meta.arg.originalArgs.plan].history = [];
      state.billing.plans[meta.arg.originalArgs.plan].active_card = '';
      state.billing.plans[meta.arg.originalArgs.plan].status = 'CANCELED';
    });

    builder.addMatcher(
      extendedBillingSlice.endpoints.getSubscriptionHistory.matchFulfilled,
      (state, { payload, meta }) => {
        state.billing.plans[meta.arg.originalArgs.plan].history = payload;
      },
    );
  },
});

// Selectors
export const selectCustomerIdentification = (state: RootState) => state.auth.profile.user_identification as string;
export const selectCustomerEmail = (state: RootState) => state.auth.profile.user_email as string;
export const isAuthenticated = (state: RootState) => state.auth.is_authenticated;
export const isRequiredPasswordUpdate = (state: RootState) => state.auth.is_requiredPasswordUpdate;
export const isUserNew = (state: RootState) => state.auth.is_new_user;
export const isActiveAccount = (state: RootState) => state.auth.is_active;
export const isPaymentVerifiedAccount = (state: RootState) => state.auth.is_payment_verified;
export const selectRememberMe = (state: RootState) => state.auth.is_rememberMe;
export const selectCustomerProfileData = (state: RootState) => state.auth.profile;
export const selectCustomerAvatar = (state: RootState) => state.auth.profile.user_avatar;
export const selectCUstomerPlans = (state: RootState) => state.auth.billing.plans;
export const selectCustomerTopPlan = (state: RootState) => state.auth.profile.user_top_plan as string;
export const selectTrialInfo = (state: RootState) => {
  return {
    is_trial: state.auth.is_trial,
    trial_left_days: state.auth.trial_left_days,
  };
};

type Return = (state: RootState) => string | undefined;

export const selectPermissionsList = createDraftSafeSelector(
  [(state: RootState) => selectCustomerProfileData(state)],
  (data) => data.user_permissions,
);

export const selectPermission = (permission: string): Return =>
  createDraftSafeSelector([(state: RootState) => selectCustomerProfileData(state)], (data) =>
    data.user_permissions?.includes(permission),
  );

export const MappedPlans = {
  FREE: 'LUMBERJACK_FREE',
  BUSINESS: 'LUMBERJACK_PREMIUM_BUSINESS',
  PREMIUM: 'LUMBERJACK_PREMIUM_PREMIUM',
  ENTERPRISE: 'LUMBERJACK_PREMIUM_ENTERPRISE',
  LARGE_ACCOUNT: 'LUMBERJACK_PREMIUM_LARGE_ACCOUNT',
  FREE_ONE_TIME: 'LPBOTC_876KHFD67DF',
};

export const {
  userLogin,
  userLogOut,
  clearRequiredPasswordUpdate,
  toggleRenewPaymentRequiredStatus,
  setIsNewUser,
  togglePaymentVerificationStatus,
  tokenSetter,
  setRememberMe,
} = authSlice.actions;

export default authSlice.reducer;
